import { Yrsa as GoogleFont } from 'next/font/google';
import Head from 'next/head';

export const yrsa = GoogleFont({
    weight: ['400', '500', '600', '700'],
    style: ['normal', 'italic'],
    subsets: ['latin'],
    display: 'swap',
});

export const Yrsa = () => (
    <Head>
        <style
            key="fontfaces-yrsa"
            className={yrsa.className}
            dangerouslySetInnerHTML={{
                __html: `
                :root {
                    --font-family-yrsa: Yrsa, ${yrsa.style.fontFamily}, Helvetica, Arial, sans-serif;
                }`,
            }}
        />
    </Head>
);
